


import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {
  QuestionTypes,
  TQuestionnaire,
  TQuestionnaireQuestion,
  TQuestionnaireQuestionAnswerOption
} from '@/_types/questionnaire.type';
import CookiesHelper from '@/_helpers/cookies-helper';
import questionnairesApi from '@/_api/questionnaires.api';

const QUESTIONNAIRE_COOKIE_NAME = 'questionnaire';

@Component
export default class Poll extends Vue {

  public isPollDisabled: boolean = false;
  public isMainPollSending: boolean = false;

  public get isSubmitMainPollButtonDisabled(): boolean {
    return this.isMainPollSending;
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get programId(): number {
    return (this.$route.params.programId && parseInt(this.$route.params.programId, 10)) || null;
  }

  @Prop({ type: Object })
  public readonly mainPoll: TQuestionnaire;

  @Watch('mainPoll.id', { immediate: true })
  public onPollIdChange(): void {
    this.isPollDisabled = this.getPollCookie();
  }

  public isStringNotEmpty(input: string): boolean {
    return input.trim() !== '';
  }

  public onOptionAnswerOptionClick(event: Event, answerOption: TQuestionnaireQuestionAnswerOption): void {
    const chosenButton: HTMLButtonElement = event.target as HTMLButtonElement;
    const allAnswerOptions: HTMLButtonElement[] = this.$refs['answer-option_q_' + answerOption.question_id] as HTMLButtonElement[];
    allAnswerOptions.forEach((btn) => {
      btn.classList.remove('chosen');
    });

    chosenButton.blur();
    chosenButton.classList.add('chosen');
  }

  public onMultiAnswerOptionClick(event: Event): void {
    const chosenButton: HTMLButtonElement = event.target as HTMLButtonElement;

    if (chosenButton.classList.contains('chosen')) {
      chosenButton.classList.remove('chosen');
      chosenButton.blur();
    } else {
      chosenButton.classList.add('chosen');
    }
  }

  public getPollCookie(): boolean {
    const questionnaireCookieName = QUESTIONNAIRE_COOKIE_NAME + this.mainPoll.id;
    return CookiesHelper.readCookie(questionnaireCookieName) === `${this.mainPoll.id}`;
  }

  // TODO: refactor
  public async onSubmitMainPollClick(): Promise<void> {
    const allRequests: Promise<any>[] = [];
    const questionnaireId: number = this.mainPoll.id;
    const questionnaireCookieName = QUESTIONNAIRE_COOKIE_NAME + questionnaireId;

    CookiesHelper.createCookie({
      name: questionnaireCookieName,
      value: questionnaireId,
      maxAge: (365 / 2) * 24 * 60 * 60,
    });

    this.isMainPollSending = true;

    this.mainPoll.questions.forEach((q: TQuestionnaireQuestion): void => {
      const qType: QuestionTypes = q.question_type;

      if (qType === QuestionTypes.TEXT) {
        const textareas: HTMLTextAreaElement[] = this.$refs['freeText_q_' + q.id] as HTMLTextAreaElement[];
        const answer = textareas[0].value.trim();
        if (this.isStringNotEmpty(answer)) {
          allRequests.push(questionnairesApi.putAnswer({
            eventId: this.eventId,
            questionnaireId,
            questionId: q.id,
            answerOptionId: -1,
            freeTextAnswer: answer,
          }));
        }

        return;
      }

      if (!q.answer_options || !q.answer_options.length) {
        return; // Remember, this return does not break forEach
      }

      const options: HTMLButtonElement[] = this.$refs['answer-option_q_' + q.id] as HTMLButtonElement[];

      options
        .filter(x => x.classList.contains('chosen'))
        .forEach((button: HTMLButtonElement): void => {
          if (button.classList.contains('chosen')) {
            allRequests.push(questionnairesApi.putAnswer({
              eventId: this.eventId,
              questionnaireId,
              questionId: q.id,
              answerOptionId: parseInt(button.getAttribute('data-id'), 10),
            }));
          }
        });

    });

    await Promise.all(allRequests).catch(() => {
      this.isMainPollSending = false;
    });

    this.isMainPollSending = false;
    this.isPollDisabled = this.getPollCookie();

    this.showPollSentPopup();
  }

  public showPollSentPopup(): void {
    this.$emit('pollHasBeenSent');
  }

  public getPollQuestionClasses(question: TQuestionnaireQuestion): {[key: string]: boolean} {
    const result: {[key: string]: boolean} = {};

    const questionTypeClass: string = 'poll-question-' + question.question_type;
    result[questionTypeClass] = true;

    return result;
  }
}
